@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes rotateAndScale {
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

/* Styles the scrollbar track (background) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Styles the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #4068e3; /* Thumb color */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Optional: Styles the scrollbar track */
::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

.element {
  scrollbar-color: #4068e3 #f0f0f0; /* thumb and track color */
  scrollbar-width: thin; /* 'auto', 'thin', or 'none' */
}
